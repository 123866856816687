import config from '../../../config/config.json';
export const LOADING_MENU = "LOADING_MENU";
export const LOADING_MENU_FLOTANTE = "LOADING_MENU_FLOTANTE";
export const ADD_ITEM_MENU_FLOTANTE = "ADD_ITEM_MENU_FLOTANTE";

const optionsMenu = [
  {
    id: 1,
    name: "homePage.principal",
    icon: "home",
    url: "/",
    idName: "principal",
    statusApp: true,
    statusWeb: true,
    public: true,
    esVendedor: 1,
    esCajero: true,
    subMenu: [],
  },
  {
    id: 2,
    name: "consulta saldo",
    icon: "money",
    url: "/saldo-cuenta",
    idName: "cuentasaldo",
    statusApp: false,
    statusWeb: false,
    public: false,
    esVendedor: 1,
    esCajero: false,
    subMenu: [],
  },
  {
    id: 3,
    name: "homePage.pagar",
    icon: "producto",
    url: "/mi-producto",
    idName: "/productos",
    statusApp: true,
    statusWeb: false,
    public: true,
    esVendedor: 1,
    esCajero: false,
    subMenu: [],
  },
  {
    id: 9,
    name: "homePage.verTerrenos",
    icon: "proyectos",
    url: "/proyectos",
    idName: "proyectos",
    statusApp: true,
    statusWeb: false,
    public: false,
    esVendedor: 1,
    esCajero: false,
    clienteBloqueadoTerreo: false,
    subMenu: [],
  },
  {
    id: 10,
    name: "homePage.verMembresias",
    icon: "membresias",
    url: "/membresias",
    idName: "membresias",
    statusApp: true,
    statusWeb: false,
    public: false,
    esVendedor: 1,
    esCajero: false,
    subMenu: [],
  },
  {
    id: 33,
    name: "homePage.membresiaInternacional",
    icon: "membresia-internacional",
    url: "/membresia-internacional",
    idName: "membresia-internacional",
    statusApp: true,
    statusWeb: false,
    public: false,
    esVendedor: 1,
    esCajero: false,
    subMenu: [],
  },
  
  {
    id: 4,
    name: "homePage.productosVendidos",
    icon: "vendido",
    url: "/Producto/Vendido",
    idName: "productovendido",
    statusApp: true,
    statusWeb: false,
    public: false,
    esVendedor: 1,
    esCajero: false,
    subMenu: [],
  },
  {
    id: 15,
    name: "homePage.reservasCabana",
    icon: "cabain",
    url: "/reservar-cabana",
    idName: "reservar-cabana",
    statusApp: config.WEB_HOTEL_CABANHA.VISIBILIDAD_MENU_VISTA_APP,
    statusWeb: false,
    public: false,
    esVendedor: 1,
    esCajero: true,
    subMenu: [],
  },
  {
    id: 14,
    name: "homePage.redClientes",
    icon: "redClientes",
    url: "/red/clientes",
    idName: "red-clientes",
    statusApp: true,
    statusWeb: false,
    public: false,
    esVendedor: 1,
    esCajero: false,
    subMenu: [],
  },
  {
    id: 6,
    name: "homePage.tarjetasPrepago",
    icon: "tarjeta-prepago",
    url: "/tarjetas-prepago",
    idName: "tarjetas-prepago",
    statusApp: true,
    statusWeb: false,
    public: false,
    esVendedor: 1,
    esCajero: false,
    subMenu: [],
  },
  {
    id: 5,
    name: "homePage.productos",
    icon: "home",
    url: "/",
    idName: "principal",
    statusApp: false,
    statusWeb: true,
    public: false,
    esVendedor: 1,
    esCajero: true,
    subMenu: [
      {
        id: 0,
        name: "homePage.terrenos",
        icon: "vendido",
        url: "/proyectos",
        idName: "terrenos",
        statusApp: true,
        statusWeb: true,
        esCajero: false,
      },
      {
        id: 1,
        name: "homePage.membresias",
        icon: "vendido",
        url: "/membresias",
        idName: "membresia",
        statusApp: true,
        statusWeb: true,
        esCajero: false,
      },
      {
        id: 2,
        name: "homePage.membresiaInternacional",
        icon: "vendido",
        url: "/membresia-internacional",
        idName: "membresia-internacional",
        statusApp: true,
        statusWeb: true,
        esCajero: false,
      },
      {
        id: 3,
        name: "homePage.reservasCabana",
        icon: "cabain",
        url: "/reservar-cabana",
        idName: "reservar-cabana",
        statusApp: config.WEB_HOTEL_CABANHA.VISIBILIDAD_MENU_VISTA_APP,
        statusWeb: config.WEB_HOTEL_CABANHA.VISIBILIDAD_MENU_VISTA_WEB,
        esCajero: true,
      },
      {
        id: 4,
        name: "homePage.suscripSion",
        icon: "vendido",
        url: "/suscripcion-sion",
        idName: "suscripcion-sion",
        statusApp: true,
        statusWeb: true,
        esCajero: false,
      },
    ],
  },
  {
    id: 6,
    name: "homePage.pagar",
    icon: "home",
    url: "/mi-producto",
    idName: "principal",
    statusApp: false,
    statusWeb: true,
    public: true,
    esVendedor: 1,
    esCajero: false,
    subMenu: [],
  },
  {
    id: 34,
    name: "homePage.menuIncentivos",
    icon: "home",
    url: "/incentivo",
    idName: "homePage.menuIncentivo",
    statusApp: true,
    statusWeb: true,
    public: false,
    esVendedor: 1,
    esCajero: true,
    subMenu: [],
  },
  {
    id: 11,
    name: "homePage.ranking",
    icon: "ranking",
    url: "/ranking",
    idName: "principal",
    statusApp: false,
    statusWeb: false,
    public: false,
    esVendedor: 1,
    esCajero: false,
    subMenu: [],
  },
  {
    id: 12,
    name: "homePage.capacitacion",
    icon: "capacitacion",
    url: "/capacitacion",
    idName: "capacitacion",
    statusApp: false,
    statusWeb: false,
    public: false,
    esVendedor: 1,
    esCajero: false,
    subMenu: [],
  },
  {
    id: 13,
    name: "homePage.eventos",
    icon: "eventos",
    url: "/eventos",
    idName: "eventos",
    statusApp: true,
    statusWeb: false,
    public: false,
    esVendedor: 1,
    esCajero: false,
    subMenu: [],
  },
  {
    id: 17,
    name: "homePage.eventosACI",
    icon: "eventos",
    url: "/eventos-aci",
    idName: "eventos-aci",
    statusApp: true,
    statusWeb: false,
    public: false,
    esVendedor: 1,
    esCajero: false,
    subMenu: [],
  },
  {
    id: 18,
    name: "homePage.suscripSion",
    icon: "suscripcion",
    url: "/suscripcion-sion",
    idName: "suscripcion-sion",
    statusApp: true,
    statusWeb: false,
    public: true,
    esVendedor: 1,
    esCajero: false,
    subMenu: [],
  },
  {
    id: 15,
    name: "Tramites",
    icon: "tramite",
    url: "/tramites",
    idName: "tramites",
    statusApp: true,
    statusWeb: false,
    public: true,
    esVendedor: 1,
    esCajero: false,
    subMenu: [],
  },

  {
    id: 16,
    name: "homePage.miCarnetkalomai",
    icon: "carnet-kalomai",
    url: "/carnet-kalomai",
    idName: "carnet-kalomai",
    statusApp: true,
    statusWeb: false,
    public: true,
    esVendedor: 1,
    esCajero: false,
    subMenu: [],
  },
  {
    id: 11,
    name: "homePage.bonoMejoresVendedores",
    icon: "ranking",
    url: "/bono-mejor-vendedor",
    idName: "principal",
    statusApp: false,
    statusWeb: false,
    public: false,
    esVendedor: 1,
    esCajero: false,
    subMenu: [],
  },
  {
    id: 9,
    name: "Tickets",
    icon: "ticket",
    url: "/Tickets",
    idName: "tickets",
    statusApp: false,
    statusWeb: false,
    public: false,
    esVendedor: 1,
    esCajero: false,
    subMenu: [],
  },

];

const optionsMenuFlotante = [
  {
    id: 1,
    name: "homePage.perfil",
    icon: "persona",
    url: "/usuario",
    idName: "/perfil",
    statusApp: true,
    statusWeb: true,
    public: true,
    esVendedor: 0,
    esCajero: true,
    subMenu: [],
  },
  {
    id: 2,
    name: "homePage.pagar",
    icon: "producto",
    url: "/mi-producto",
    idName: "/productos",
    statusApp: true,
    statusWeb: true,
    public: true,
    esVendedor: 1,
    esCajero: false,
    subMenu: [],
  },
  {
    id: 9,
    name: "homePage.verTerrenos",
    icon: "proyectos",
    url: "/proyectos",
    idName: "proyectos",
    statusApp: true,
    statusWeb: true,
    public: false,
    esVendedor: 1,
    esCajero: false,
    subMenu: [],
  },
  {
    id: 10,
    name: "homePage.verMembresias",
    icon: "membresias",
    url: "/membresias",
    idName: "membresias",
    statusApp: true,
    statusWeb: true,
    public: false,
    esVendedor: 1,
    esCajero: false,
    subMenu: [],
  },
  {
    id: 33,
    name: "homePage.membresiaInternacional",
    icon: "membresia-internacional",
    url: "/membresia-internacional",
    idName: "membresia-internacional",
    statusApp: true,
    statusWeb: true,
    public: false,
    esVendedor: 1,
    esCajero: false,
    subMenu: [],
  },
  {
    id: 3,
    name: "homePage.productosVendidos",
    icon: "vendido",
    url: "/Producto/Vendido",
    idName: "productovendido",
    statusApp: true,
    statusWeb: true,
    public: false,
    esVendedor: 1,
    esCajero: false,
    subMenu: [],
  },
  {
    id: 15,
    name: "homePage.reservasCabana",
    icon: "cabain",
    url: "/reservar-cabana",
    idName: "reservar-cabana",
    statusApp: config.WEB_HOTEL_CABANHA.VISIBILIDAD_MENU_VISTA_APP,
    statusWeb: config.WEB_HOTEL_CABANHA.VISIBILIDAD_MENU_VISTA_WEB,
    public: false,
    esVendedor: 1,
    esCajero: false,
    subMenu: [],
  },
  {
    id: 13,
    name: "homePage.redClientes",
    icon: "redClientes",
    url: "/red/clientes",
    idName: "red-clientes",
    statusApp: true,
    statusWeb: true,
    public: false,
    esVendedor: 1,
    esCajero: false,
    subMenu: [],
  },
  {
    id: 4,
    name: "homePage.tarjetasPrepago",
    icon: "tarjeta-prepago",
    url: "/tarjetas-prepago",
    idName: "tarjetas-prepago",
    statusApp: true,
    statusWeb: true,
    public: false,
    esVendedor: 1, //vendedores
    esCajero: false,
    subMenu: [],
  },
  {
    id: 7,
    name: "homePage.capacitacion",
    icon: "capacitacion",
    url: "/capacitacion",
    idName: "capacitacion",
    statusApp: false,
    statusWeb: false,
    public: false,
    esVendedor: 0,
    esCajero: false,
    subMenu: [],
  },
  {
    id: 8,
    name: "homePage.eventos",
    icon: "eventos",
    url: "/eventos",
    idName: "eventos",
    statusApp: true,
    statusWeb: true,
    public: false,
    esVendedor: 1,
    esCajero: false,
    subMenu: [],
  },
  {
    id: 17,
    name: "homePage.eventosACI",
    icon: "eventos",
    url: "/eventos-aci",
    idName: "eventos-aci",
    statusApp: true,
    statusWeb: true,
    public: false,
    esVendedor: 1,
    esCajero: false,
    subMenu: [],
  },
  {
    id: 11,
    name: "Tramites",
    icon: "tramite",
    url: "/tramites",
    idName: "tramites",
    statusApp: true,
    statusWeb: true,
    public: true,
    esVendedor: 1,
    esCajero: false,
    subMenu: [],
  },
  {
    id: 12,
    name: "homePage.miCarnetkalomai",
    icon: "carnet-kalomai",
    url: "/carnet-kalomai",
    idName: "carnet-kalomai",
    statusApp: true,
    statusWeb: true,
    public: true,
    esVendedor: 1,
    esCajero: false,
    subMenu: [],
  },
  {
    id: 16,
    name: "Tickets",
    icon: "ticket",
    url: "/Tickets",
    idName: "tickets",
    statusApp: false,
    statusWeb: false,
    public: false,
    esVendedor: 1,
    esCajero: false,
    subMenu: [],
  },

];

const addItem = {
  id: 13,
  name: "calcularRecuperacion.textoMenu",
  icon: "calcular-recuperacion",
  url: "/calcular-recuperacion-producto",
  idName: "calcular-recuperacion",
  statusApp: true,
  statusWeb: false,
  public: true,
  esVendedor: 1,
  esCajero: false,
  subMenu: [],
}

export function loadingMenu() {
  return (dispatch) => {
    dispatch({
      type: LOADING_MENU,
      load: true,
      listMenu: optionsMenu,
    });
    dispatch({
      type: LOADING_MENU_FLOTANTE,
      listSubMenuFlotante: optionsMenuFlotante,
    });
  };
}

export function addMenuCalcular() {
  return (dispatch) => {
    dispatch({
      type: LOADING_MENU,
      load: true,
      listMenu: [...optionsMenu, addItem],
    });
    dispatch({
      type: LOADING_MENU_FLOTANTE,
      listSubMenuFlotante: [...optionsMenuFlotante, { ...addItem, statusWeb: true, }],
    });
  };
}
